import React from "react";
import "./background.css"
import Call from "./call";
import CallRejection from "./call_rejection";
import Icon from "./icon";


const BackGround = () => (
    <div className="back">
        <Icon />
        <Call />
        <CallRejection />
    </div>
);

export default BackGround;