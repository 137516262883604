import { ImPhoneHangUp } from "react-icons/im";
import "./call.css"


const CallRejection = () => (
    <div className="call_rejection">
        <div className="circle_red"><ImPhoneHangUp color="white" size="10vw"/></div>
        <div className="circle_name">拒否</div>
    </div>
);

export default CallRejection;
