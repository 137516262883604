import "./icon.css"


const Icon = () => (
    <>
        <div className="icon">
            <div className="circle_icon"></div>
        </div>
        <div className="icon_anime">
            <div className="circle_icon_anime"></div>
        </div>
    </>
);

export default Icon;
