import React from "react";
import './App.css';
import BackGround from './components/background';

function App() {
  return (
    <div className="App">
      <BackGround />
    </div>
  );
}

export default App;
