import { ImPhone } from "react-icons/im";
import "./call.css"


const Call = () => (
    <>
        <div className="call">
            <div className="circle"><ImPhone color="white" size="10vw" /></div>
            <div className="circle_name">応答</div>
        </div>
        
    </>
);

export default Call;
